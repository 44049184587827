import "./App.css";
import digestAuthRequest from "digest-auth-request";
import React from "react";

function App() {
  const [deviceInfo, setDeviceInfo] = React.useState();
  const [req, setReq] = React.useState();

  var url = "http://192.168.1.21:80";

  // create digest request object
  var getRequest = new digestAuthRequest("GET", url, "admin", "a8050029501");
  var putRequest = new digestAuthRequest("PUT", url, "admin", "a8050029501");
  window.auth = getRequest;
  window.putAuth = putRequest;

  const handleDeviceInfo = () => {
    // make the request
    getRequest.request(
      function (data) {
        console.log("response", data);
        setDeviceInfo(data);
        // success callback
        // do something with the data
      },
      function (errorCode) {
        console.log("error", errorCode);
        // error callback
        // tell user request failed
      }
    );
  };

  const handlePut = () => {
    // make the request
    putRequest.request(
      function (data) {
        console.log("response", data);
        setReq(data);
        // success callback
        // do something with the data
      },
      function (errorCode) {
        console.log("error", errorCode);
        // error callback
        // tell user request failed
      }
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <div style={{marginBottom:"20px"}}>
          <button onClick={handleDeviceInfo}>Get Device info</button>
          {deviceInfo && <pre>{JSON.stringify(deviceInfo, null, 2)}</pre>}
        </div>
        <div>
          <button onClick={handlePut}>Put call</button>
          {req && <pre>{JSON.stringify(req, null, 2)}</pre>}
        </div>
      </header>
    </div>
  );
}

export default App;
